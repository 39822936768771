import React, { FunctionComponent } from "react";
import { Link } from "gatsby";

import Layout from "../layout";
import SEO from "../components/seo";
import { IdentityUserContext } from "../components/identity/Identity";
import {
	Card,
	CardTitle,
	CardText,
	CardActions,
	Button,
	Cell,
	Grid,
} from "react-md";

const IndexPage: FunctionComponent<{}> = () => {
	return (
		<Layout title="Home Page">
			<IdentityUserContext.Consumer>
				{user => (
					<>
						<SEO title="Home" />
						{/*user ? <h1>Hi {user.contact.fullName}</h1> : <h1>Hi people</h1>*/}
						<Grid>
							<Cell>
								<Card>
									<CardTitle title="Pay Here" />
									<CardText>
										You can pay your montly dues, or for a single ride.
									</CardText>
									<CardActions centered>
										<Link to="/payments">
											<Button raised primary>
												Payments Page
											</Button>
										</Link>
									</CardActions>
								</Card>
							</Cell>
							<Cell>
								<Card>
									<CardTitle title="Text alerts." />
									<CardText>Sign up for text-message alerts.</CardText>
									<CardActions centered>
										<Link to="/alerts">
											<Button raised primary>
												Sign Up
											</Button>
										</Link>
									</CardActions>
								</Card>
							</Cell>
						</Grid>
					</>
				)}
			</IdentityUserContext.Consumer>
		</Layout>
	);
};

export default IndexPage;
